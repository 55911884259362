import { useCallback, useEffect } from 'react'

import Mixpanel, { Dict } from 'mixpanel-browser'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import {
  getBelongsToLiveOrganisation,
  getHasCozeroEmail,
  getIsCozeroAdmin,
  selectUser,
} from '@/redux/auth'
import { config } from '@/utils/config'

const isProd = 'production' === config.NODE_ENV

Mixpanel.init(config.MIXPANEL_ID ?? 'dev', {
  ip: false,
  api_host: 'https://api-eu.mixpanel.com',
  debug: !isProd,
})

const useMixpanelDisabled = (): { disabled: boolean } => {
  const user = useAppSelector(selectUser)
  return {
    disabled: isProd && !!user && !user.organization?.telemetry,
  }
}

export const useInitMixpanel = (): void => {
  const user = useAppSelector(selectUser)

  const { disabled } = useMixpanelDisabled()

  const isCozeroAdmin = useAppSelector(getIsCozeroAdmin)
  const hasCozeroEmail = useAppSelector(getHasCozeroEmail)
  const belongToLiveOrganisation = useAppSelector(getBelongsToLiveOrganisation)

  useEffect(() => {
    if (disabled || typeof user?.id !== 'number') {
      return Mixpanel.reset()
    }

    const test = !isProd || isCozeroAdmin || hasCozeroEmail || !belongToLiveOrganisation

    const data = {
      user_id: user.id,
      created_at: user.createdAt,
      organization_id: user.organization?.id,
      organization_name: user.organization?.name,
      business_unit_id: user.businessUnit?.id,
      business_unit_title: user.businessUnit?.title,
      pricing: user.organization?.pricing?.name,
      industry: user.organization?.industry?.name,
      size: user.organization?.totalEmployees,
      role: user.role?.name,
      locale: user.locale,
      test,
    }

    Mixpanel.identify(`${user.id}`)
    Mixpanel.register(data)
    Mixpanel.people.set(data)
    Mixpanel.track('Session start')
  }, [user?.id])
}

export const useMixpanel = (): {
  trackPageView: (properties: Dict) => void
  trackAction: (category: AnalyticsCategories, action: string, properties?: Dict) => void
} => {
  const { disabled } = useMixpanelDisabled()

  const track: typeof Mixpanel.track = useCallback((...args) => {
    if (!Mixpanel.track || disabled) {
      return
    }

    Mixpanel.track(...args)
  }, [])

  const trackPageView = useCallback(
    (properties: Dict) => {
      track('page-view', properties)
    },
    [track],
  )

  const trackAction = useCallback(
    (category: string, action: string, properties?: Dict) => {
      track(`${category} - ${action}`, properties)
    },
    [track],
  )

  return {
    trackPageView,
    trackAction,
  }
}
